import React from "react";

import { graphql, Link, useStaticQuery } from "gatsby";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

import { Container, Wrapper } from "../components/layout";
import { Layout } from "../components/layout/layout";
import { FromBottom } from "../components/transitions";

const Schedule = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "horaire.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout background={data.hero} title="Horaire">
      <Helmet>
        <title>Centre Léveil - Horaire</title>
      </Helmet>
      <Wrapper>
        <FromBottom isActive={!isMobile}>
          <Container>
            <h2>
              Consultez notre horaire sur{" "}
              <Link
                to="https://leveilcrossfit.fliipapp.com/horaire/eccbc87e4b5ce2fe28308fd9f2a7baf3/DefaultRoom"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "var(--color-primary)",
                  borderBottom: "2px solid white",
                }}
              >
                Fliip
              </Link>
            </h2>
          </Container>
        </FromBottom>
      </Wrapper>
      {!isMobile && (
        <Wrapper style={{ backgroundColor: "#1b1d1f" }}>
          <Container
            direction="column"
            style={{ width: "100%", maxWidth: "90%" }}
          >
            <iframe
              src="https://leveilcrossfit.fliipapp.com/horaire/eccbc87e4b5ce2fe28308fd9f2a7baf3/DefaultRoom"
              frameBorder="0"
              allowfullscreen
              style={{ height: "1350px", width: "100%" }}
            ></iframe>
          </Container>
        </Wrapper>
      )}
    </Layout>
  );
};

export default Schedule;
